<template>
  <div>
    <!-- Start: Top -->
    <div class="d-flex align-center">
      <div class="text-h5 font-weight-bold">Build thanh categories</div>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="categoriesBarStatusRequest === 'loading-updateCategoriesBar'"
        :loading="categoriesBarStatusRequest === 'loading-updateCategoriesBar'"
        class="rounded-lg"
        color="primary"
        depressed
        @click="updateCategoriesBar"
      >
        Lưu
      </v-btn>

      <v-btn
        :disabled="categoriesBarStatusRequest === 'loading-getCategoriesBar'"
        :loading="categoriesBarStatusRequest === 'loading-getCategoriesBar'"
        class="font-weight-bold grey--text text--darken-3 rounded-lg ml-3"
        color="grey lighten-2"
        depressed
        @click="getCategoriesBar"
      >
        Hủy
      </v-btn>
    </div>
    <!-- End: Top -->
    <div class="mt-4" style="max-width: 800px">
      <h6>Tất cả loại sản phẩm ({{ categoriesBarList.length }})</h6>

      <v-card
        v-for="(item, index) in categoriesBarList"
        :key="item.id"
        class="card-draggable d-flex align-center rounded-lg px-5 py-2 mt-3"
        draggable="true"
        flat
        @dragend="dragEnd"
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        @dragover="dragOver"
        @dragstart="dragStart($event, item, index)"
        @drop="dragDrop($event, item, index)"
      >
        <div class="d-flex align-center flex-grow-1">
          <div>
            <v-img
              :src="item.icon"
              aspect-ratio="1"
              class="rounded tp-border-thin"
              height="40px"
              width="40px"
            ></v-img>
          </div>

          <div class="font-weight-bold ml-4">{{ item.name }}</div>
        </div>

        <v-divider class="mx-4" vertical></v-divider>

        <v-checkbox
          v-model="item.cate_bar_show"
          :false-value="0"
          :true-value="1"
          class="mt-0 pt-0"
          color="green"
          dense
          hide-details
          label="Hiển thị trên categories bar"
        ></v-checkbox>
      </v-card>
    </div>
  </div>
</template>

<script>
import { showModalAlertError, showToastActionAlert } from "@/core/composables";

export default {
  data() {
    return {
      draggableItem: null,
      draggableItemIndex: null
    };
  },

  computed: {
    categoriesBarList() {
      return this.$store.getters["CATEGORIES_BAR/categoriesBarList"];
    },

    categoriesBarStatusRequest() {
      return this.$store.getters["CATEGORIES_BAR/statusRequest"];
    }
  },

  created() {
    this.getCategoriesBar();
  },

  methods: {
    dragStart(event, item, index) {
      event.target.classname += " hold";

      this.draggableItem = item;
      this.draggableItemIndex = index;

      setTimeout(() => {
        event.target.className += " invisible";
      }, 0);
    },
    dragEnd(event) {
      event.target.className =
        "card-draggable d-flex align-center rounded-lg px-5 py-2 mt-3 v-card v-card--flat v-sheet theme--light";
    },

    dragOver(event) {
      event.preventDefault();
    },

    dragEnter(event) {
      event.preventDefault();
      event.target.style.borderColor = "#27BECE";
    },

    dragLeave(event) {
      event.target.style.borderColor = "white";
    },

    async dragDrop(event, item, index) {
      event.target.style.borderColor = "white";

      this.categoriesBarList.splice(this.draggableItemIndex, 1);
      this.categoriesBarList.splice(index, 0, this.draggableItem);
    },

    async getCategoriesBar() {
      await this.$store.dispatch("CATEGORIES_BAR/getCategoriesBar");
    },

    async updateCategoriesBar() {
      const arr = this.categoriesBarList.map((item, index) => {
        item.cate_bar_priority = index + 1;

        return item;
      });

      await this.$store.dispatch("CATEGORIES_BAR/updateCategoriesBar", [
        ...arr
      ]);

      if (
        this.categoriesBarStatusRequest.value === "success-updateCategoriesBar"
      ) {
        showToastActionAlert(this, { message: "Đã lưu cập nhật" });
      } else if (
        this.categoriesBarStatusRequest.value === "error-updateCategoriesBar"
      ) {
        showModalAlertError(this, {
          title: "Không thể cập nhật thay đổi",
          message: this.categoriesBarStatusRequest.message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-draggable {
  border-width: 2px;
  border-color: white;
  cursor: move;
}

.hold {
  opacity: 0.5;
}

.invisible {
  opacity: 0.5;
}
</style>
